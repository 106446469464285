<section id="signed-container" class="mat-elevation-z8">
  <div class="info">
    <h1>Sesión iniciada. </h1>
    <h3>{{datos?.email}}</h3>
    <h4>{{datos?.nickname}}</h4>
  </div>
  <div class="exit">
    <button mat-raised-button color="primary" (click)="signOut()"> Salir</button>
  </div>
</section>
