<section id="forgot-container" class="mat-elevation-z8">
  <form [formGroup]="form" id="form" (ngSubmit)="sendEmail()">
    <mat-form-field floatPlaceholder="auto" controlErrorContainer>
      <input matInput formControlName="email"
             type="email" autocomplete="off"
             placeholder="Email para enviar código de recuperación *">
    </mat-form-field>

    <div class="actions">
      <button mat-raised-button color="primary" form="form">Enviar código de recuperación</button>
      <button mat-raised-button color="primary" routerLink="/auth/sign-in">Iniciar Sesión</button>
    </div>
  </form>
</section>
