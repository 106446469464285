<section id="confirm-code-container" class="mat-elevation-z8">
  <h1> Confirmación de la cuenta</h1>
  <mat-divider></mat-divider>
  <div class="title">
    <label>
      Utilice el código de confirmación
    </label>
    <label>
      enviado a su correo electrónico.
    </label>
  </div>
  <form [formGroup]="form" id="form" (ngSubmit)="signIn()">
    <mat-form-field floatPlaceholder="auto" controlErrorContainer>
      <input matInput formControlName="email"
             type="email" autocomplete="off"
             placeholder="Email *">
    </mat-form-field>

    <mat-form-field floatPlaceholder="auto" controlErrorContainer>
      <input matInput formControlName="code"
             type="text" autocomplete="off"
             placeholder="Código de confirmación *">
    </mat-form-field>

    <div class="actions">
      <button mat-raised-button routerLink="/auth/sign-in"> Cancelar</button>
      <button mat-raised-button color="primary" form="form"> Confirmar</button>
    </div>

  </form>
</section>
