import {Injectable} from '@angular/core';
import {Auth} from 'aws-amplify';
import {Router} from '@angular/router';
import {Subject} from 'rxjs';
import {JwtHelperService} from '@auth0/angular-jwt';
import {NgxSpinnerService} from 'ngx-spinner';
import {MatSnackBar} from '@angular/material/snack-bar';
import {tap} from 'rxjs/operators';

export interface AwsNotification {
  code?: string;
  msg?: string;
  name?: string;
  type?: MSG_Type;
}

export enum MSG_Type {
  CONFIRM_CODE_ERROR = 'CONFIRM_CODE_ERROR',
  CONFIRM_CODE_SUCCESS = 'CONFIRM_CODE_SUCCESS',
  SIGN_IN_ERROR = 'SIGN_IN_SUCCESS'
}

export enum AwsErrorType {
  CODIGO_INCORRECTO = 'CodeMismatchException',
  CODIGO_EXPIRADO = 'ExpiredCodeException',
  USUARIO_CONFIRMADO = 'NotAuthorizedException',
  SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS',
  USER_NO_FOUND = 'UserNotFoundException',
  SIGN_IN_USER_OR_PASS_WRONG = 'NotAuthorizedException',
  SIGN_IN_USER_NOT_CONFIRMED = 'UserNotConfirmedException',
  SIGN_UP_USER_EXIST = 'UsernameExistsException',
  FORGOT_INVALID_USER = 'InvalidParameterException',
  FORGOT_LIMIT_EXCEDED = 'LimitExceededException',
  INVALID_PASSWORD = 'InvalidPasswordException',

}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  notif$: Subject<AwsNotification> = new Subject<AwsNotification>();

  constructor(private _snackBar: MatSnackBar,
              private spinner: NgxSpinnerService,
              private router: Router,
              private jwtHelper: JwtHelperService) {
  }

  notif() {
    return this.notif$.asObservable();
  }

  signOut() {

    Auth.signOut().then(value => {
      this.router.navigate(['/auth/sign-in']);
    }).catch(err => {
      this.spinner.hide();
    });
  }

  confirmCode(username, code) {
    this.spinner.show();

    Auth.confirmSignUp(username, code).then(value => {
      this.spinner.hide();
      this.openSuccessMessage({msg: 'Código confirmado correctamente.'})
        .pipe(
          tap(x => {
          })
        )
        .subscribe();
      this.router.navigate(['/auth/sign-in'], {queryParams: {user: username}});

    }).catch(err => {
      const error = {...err, type: MSG_Type.CONFIRM_CODE_ERROR};
      if (error.code === AwsErrorType.CODIGO_INCORRECTO) {
        error.msg = 'Código de confirmación incorrecto.';
      }
      if (error.code === AwsErrorType.USUARIO_CONFIRMADO) {
        error.msg = 'Este correo ya ha sido confirmado.';
      }
      if (error.code === AwsErrorType.USER_NO_FOUND) {
        error.msg = 'Este correo no está registrado.';
      }
      this.openErrorMessage(error);
      this.spinner.hide();
    });
  }

  signUp(username, password, attributes) {
    this.spinner.show();

    Auth.signUp({username, password, attributes}).then(value => {
      this.spinner.hide();
      this.openSuccessMessage({msg: 'Cuenta creada correctamente.'})
        .pipe(
          tap(x => {
          })
        )
        .subscribe();
      this.router.navigate(['/auth/confirm-code'], {queryParams: {user: username}});

    }).catch(err => {
      const error: AwsNotification = {...err};
      if (error.code === AwsErrorType.SIGN_UP_USER_EXIST) {
        error.msg = 'Este correo ya está registrado.';
      }
      if (error.code === AwsErrorType.INVALID_PASSWORD) {
        error.msg = 'La contraseña debe incluir mayúsculas, minúsculas y caracteres especiales.';
      }
      this.openErrorMessage(error);
      this.spinner.hide();
    });
  }

  signInUser(username, password, redirectTo, localRedirecTo) {
    this.spinner.show();
    Auth.signIn(username, password).then(({signInUserSession}) => {
      this.spinner.hide();
      if (!!redirectTo) {
        window.location.href = redirectTo;
      } else {
        this.router.navigate([localRedirecTo]);
      }
    }).catch(err => {
      const error: AwsNotification = {...err};
      if (error.code === AwsErrorType.USER_NO_FOUND) {
        error.msg = 'Este correo no existe.';
      }
      if (error.code === AwsErrorType.SIGN_IN_USER_OR_PASS_WRONG) {
        error.msg = 'Correo o Contraseña incorrecto.';
      }
      if (error.code === AwsErrorType.SIGN_IN_USER_NOT_CONFIRMED) {
        error.msg = 'Este correo no ha sido confirmado.';
      }
      this.openErrorMessage(error);
      this.openErrorMessage(error)
        .pipe(
          tap(x => {
            if (error.code === AwsErrorType.SIGN_IN_USER_NOT_CONFIRMED) {
              this.router.navigate(['/auth/confirm-code'], {queryParams: {user: username}});
            }
          })
        )
        .subscribe();
      this.spinner.hide();
    });
  }

  forgotPassword(username, localRedirecTo) {
    this.spinner.show();
    Auth.forgotPassword(username).then(() => {
      this.spinner.hide();
      this.openSuccessMessage({msg: `Código de recuperación enviado a ${username}`})
        .pipe(
          tap(x => {
            // this.router.navigate(['/auth/confirm-code'], {queryParams: {email: username}});
          })
        )
        .subscribe();
      this.router.navigate([localRedirecTo]);
    }).catch(err => {
      const error: AwsNotification = {...err};
      if (error.code === AwsErrorType.USER_NO_FOUND) {
        error.msg = 'Este correo no existe.';
      }
      if (error.code === AwsErrorType.SIGN_IN_USER_NOT_CONFIRMED) {
        error.msg = 'Este correo no ha sido confirmado.';
      }
      if (error.code === AwsErrorType.FORGOT_INVALID_USER) {
        error.msg = 'Debe especificar un correo registrado y confirmado anteriormente.';
      }
      if (error.code === AwsErrorType.FORGOT_LIMIT_EXCEDED) {
        error.msg = 'Ha excedido el límite de intentos, inténtelo dentro de unos minutos.';
      }
      this.openErrorMessage(error);
      this.openErrorMessage(error)
        .pipe(
          tap(x => {
            if (error.code === AwsErrorType.SIGN_IN_USER_NOT_CONFIRMED) {
              this.router.navigate(['/auth/confirm-code'], {queryParams: {user: username}});
            }
          })
        )
        .subscribe();
      this.spinner.hide();
    });
  }

  forgotPasswordSubmit(username, code, password) {
    this.spinner.show();
    Auth.forgotPasswordSubmit(username, code, password).then(() => {
      this.spinner.hide();
      this.openSuccessMessage({msg: `Contraseña restrablecida correctamente`})
        .pipe(
          tap(x => {
            // this.router.navigate(['/auth/confirm-code'], {queryParams: {email: username}});
          })
        )
        .subscribe();
      this.router.navigate(['/auth/sign-in'], {queryParams: {user: username}});

    }).catch(err => {
      const error: AwsNotification = {...err};
      if (error.code === AwsErrorType.USER_NO_FOUND) {
        error.msg = 'Este correo no existe.';
      }
      if (error.code === AwsErrorType.SIGN_IN_USER_NOT_CONFIRMED) {
        error.msg = 'Este correo no ha sido confirmado.';
      }
      if (error.code === AwsErrorType.FORGOT_INVALID_USER) {
        error.msg = 'Debe especificar un correo registrado y confirmado anteriormente.';
      }
      if (error.code === AwsErrorType.CODIGO_EXPIRADO) {
        error.msg = 'Código expirado o usado anteriormente.';
      }

      if (error.code === AwsErrorType.FORGOT_LIMIT_EXCEDED) {
        error.msg = 'Ha excedido el límite de intentos, inténtelo dentro de unos minutos.';
      }
      this.openErrorMessage(error);
      // this.router.navigate(['/auth/sign-in'], {queryParams: {email: username}});
      this.spinner.hide();
    });
  }

  decodeToken(token) {
    return this.jwtHelper.decodeToken(token);
  }

  openErrorMessage(notif: AwsNotification) {
    return this._snackBar.open(notif.msg || 'Error en la operación.', 'Cerrar', {
      duration: 3000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
      panelClass: 'notif-error'
    }).afterDismissed();
  }

  openSuccessMessage(notif: AwsNotification, time = 3000) {
    return this._snackBar.open(notif.msg || 'Operación realizada correctamente.', 'Cerrar', {
      duration: time,
      horizontalPosition: 'center',
      verticalPosition: 'top',
      panelClass: 'notif-success'
    }).afterDismissed();
  }
}
