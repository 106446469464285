import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../auth.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {RxwebValidators} from '@rxweb/reactive-form-validators';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit {
  form: FormGroup;

  constructor(private authService: AuthService, private formBuilder: FormBuilder) {
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      user: ['', [Validators.required]],
      password: ['', [Validators.required, RxwebValidators.minLength({
        value: 8,
        message: 'Necesita mínimo 8 caracteres.'
      })]],
      confirm: ['', [Validators.required, RxwebValidators.compare({
        fieldName: 'password',
        message: 'Las contraseñas no coinciden'
      })]],
      email: ['', [Validators.required, Validators.email]],
    });
  }

  signUp() {
    if (this.form.valid) {
      const val = this.form.value;
      this.authService.signUp(val.user, val.password,
        {
          email: val.email,
          given_name: 'Protecta',
          family_name: 'Security'
        });
    }
  }

}
