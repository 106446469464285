// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  aws: {
    aws_project_region: 'us-east-1',
        aws_cognito_identity_pool_id: 'us-east-1:594902652773',
        aws_cognito_region: 'us-east-1',
        aws_user_pools_id: 'us-east-1_UU9Z6a0n3',
        aws_user_pools_web_client_id: 'tcq53h177im86qdnodmalvfcl',
    cookieStorage: {
      domain: '.dev.protectasecuritycloud.pe',
      path: '/',
      secure: false
    },
    oauth: {}
  },
  redirectTo: 'https://tarifario.core.dev.protectasecuritycloud.pe'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
