<section id="sign-in-container" class="mat-elevation-z8">
  <!--  <h1> Inicia Sesión</h1>-->
  <!--  <mat-divider></mat-divider>-->
  <form [formGroup]="form" id="form" (ngSubmit)="signIn()">
    <mat-form-field floatPlaceholder="auto" controlErrorContainer>
      <input matInput formControlName="user"
             autocomplete="off"
             placeholder="Usuario *">
    </mat-form-field>

    <mat-form-field floatPlaceholder="auto" controlErrorContainer>
      <input matInput formControlName="password"
             type="password" autocomplete="off"
             placeholder="Contraseña *">
    </mat-form-field>

    <div class="actions">
      <!--      <button mat-raised-button  routerLink="/auth/sign-up"> Regístrate!</button>-->
      <button mat-raised-button color="primary" form="form"> Iniciar sesión</button>
      <button mat-raised-button color="primary" form="form" (click)="goToForgotPassword()"> ¿Has olvidado la
        contraseña?
      </button>
    </div>
  </form>
</section>
