import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../auth.service';
import {Auth} from 'aws-amplify';

@Component({
  selector: 'app-signed',
  templateUrl: './signed.component.html',
  styleUrls: ['./signed.component.scss']
})
export class SignedComponent implements OnInit {
  datos: any = {};

  constructor(private authService: AuthService) {
  }

  ngOnInit(): void {
    Auth.currentSession()
      .then((session: any) => this.datos = session?.idToken?.payload);
  }

  signOut() {
    this.authService.signOut();
  }
}
