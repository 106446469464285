import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormSubmitDirective} from './directive/form-error/form-submit.directive';
import {ControlErrorComponent} from './directive/form-error/control-error/control-error.component';
import {ControlErrorContainerDirective} from './directive/form-error/control-error-container.directive';
import {ControlErrorsDirective} from './directive/form-error/control-errors.directive';
import {DisableControlDirective} from './directive/disable-reactive-control.directive';

const toInclude = [
  FormSubmitDirective,
  ControlErrorComponent,
  ControlErrorContainerDirective,
  ControlErrorsDirective,
  DisableControlDirective
];

@NgModule({
  declarations: [toInclude],
  exports: [toInclude],
  imports: [
    CommonModule
  ]
})
export class SharedModule {
}
