import {Directive, Input, Optional, Output} from '@angular/core';
import {NgControl} from '@angular/forms';
import {ReplaySubject} from 'rxjs';

@Directive({
	selector: '[appDisableControl]'
})
export class DisableControlDirective {
	@Output() stateChange = new ReplaySubject(1);

	@Input() set appDisableControl(condition: boolean) {
		const action = condition ? 'disable' : 'enable';
		if (!!this.ngControl && !!this.ngControl.control) {
			// console.log('ngControl1', this.ngControl);
			this.ngControl.control[action]();
			this.stateChange.next(condition);
		}
	}

	constructor(@Optional() private ngControl: NgControl) {
	}

}
