import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  form: FormGroup;

  constructor(
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private router: Router,
    private activateRoute: ActivatedRoute) {

  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  sendEmail() {
    const redirectTo = this.activateRoute.snapshot.queryParamMap.get('redirectTo') || environment.redirectTo;
    const localRedirectTo = '/auth/password-recover';
    if (this.form.valid) {
      const val = this.form.value;
      this.authService.forgotPassword(val.email, localRedirectTo);
    }
  }


}
