import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService} from './auth.service';
import Auth from '@aws-amplify/auth';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private auth: AuthService) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // return this.auth.isAuthenticatedGuard().pipe(tap(loggedIn => {
    //     console.log('Bloqueado por el GUARD AuthGuard', loggedIn);
    //
    //     if (!loggedIn) {
    //       this.router.navigate(['/auth/sign-in'], {queryParams: {returnUrl: state.url}});
    //     }
    //   }
    // ));

    return Auth.currentAuthenticatedUser().then((cognitoUser) => {
      const idToken = cognitoUser.getSignInUserSession().getIdToken();
      return !!idToken;
    })
      .catch(() => {
        this.router.navigate(['auth/signin']);
        return false;
      });
  }

}
