<section id="auth-container" class="mat-elevation-z8">
  <mat-tab-group [disableRipple]="false">
    <mat-tab label="Entrar">
      <app-sign-in>

      </app-sign-in>
    </mat-tab>
    <mat-tab label="Registrar">
      <app-sign-up>
          
      </app-sign-up>
    </mat-tab>
  </mat-tab-group>
</section>
