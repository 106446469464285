import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {SignUpComponent} from './auth/component/sign-up/sign-up.component';
import {SignInComponent} from './auth/component/sign-in/sign-in.component';
import {ConfirmCodeComponent} from './auth/component/confirm-code/confirm-code.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {SignedComponent} from './auth/component/signed/signed.component';
import {MatButtonModule} from '@angular/material/button';
import {MatDividerModule} from '@angular/material/divider';
import {environment} from '../environments/environment';
import Auth from '@aws-amplify/auth';
import {JwtHelperService} from '@auth0/angular-jwt';
import {NgxSpinnerModule} from 'ngx-spinner';
import {SharedModule} from './shared/shared.module';
import {RxReactiveFormsModule} from '@rxweb/reactive-form-validators';
import {MatIconModule} from '@angular/material/icon';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {AuthComponent} from './auth/component/auth/auth.component';
import {MatTabsModule} from '@angular/material/tabs';
import {ForgotPasswordComponent} from './auth/component/forgot-password/forgot-password.component';
import {ForgotPasswordSubmitComponent} from './auth/component/forgot-password-submit/forgot-password-submit.component';

Auth.configure(environment.aws);

@NgModule({
  declarations: [
    AppComponent, SignUpComponent, SignInComponent, ConfirmCodeComponent, SignedComponent, AuthComponent, ForgotPasswordComponent, ForgotPasswordSubmitComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    RxReactiveFormsModule,
    SharedModule,
    NgxSpinnerModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatDividerModule,
    MatIconModule,
    MatSnackBarModule,
    MatTabsModule
  ],
  providers: [{provide: JwtHelperService, useValue: new JwtHelperService()}],
  bootstrap: [AppComponent]
})
export class AppModule {
}
