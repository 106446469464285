<section id="sign-up-container" class="mat-elevation-z8">
  <!--  <h1> Regístrate!</h1>-->
  <!--  <mat-divider></mat-divider>-->
  <form [formGroup]="form" id="form" (ngSubmit)="signUp()">
    <mat-form-field floatPlaceholder="auto" controlErrorContainer>
      <input matInput formControlName="email"
             type="email" autocomplete="off"
             placeholder="Email para iniciar sesión*">
    </mat-form-field>
    <mat-form-field floatPlaceholder="auto" controlErrorContainer>
      <input matInput formControlName="user"
             type="text" autocomplete="off"
             placeholder="Usuario *">
    </mat-form-field>
    <mat-form-field floatPlaceholder="auto" controlErrorContainer>
      <input matInput formControlName="password"
             type="password" autocomplete="off"
             placeholder="Contraseña *">
    </mat-form-field>
    <mat-form-field floatPlaceholder="auto" controlErrorContainer>
      <input matInput formControlName="confirm"
             type="password" autocomplete="off"
             placeholder="Confirmar Contraseña *">
    </mat-form-field>

    <div class="actions">
      <!--      <button mat-raised-button  routerLink="/auth/sign-in"> Iniciar Sesión</button>-->
      <button mat-raised-button color="primary" form="form"> Registrar</button>
    </div>
  </form>
</section>
