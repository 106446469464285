import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService, AwsNotification, MSG_Type} from '../../auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {filter, switchMap, tap} from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-confirm-code',
  templateUrl: './confirm-code.component.html',
  styleUrls: ['./confirm-code.component.scss']
})
export class ConfirmCodeComponent implements OnInit {
  form: FormGroup;
  user;

  constructor(
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private router: Router,
    private activateRoute: ActivatedRoute) {

  }

  ngOnInit() {
    this.user = this.activateRoute.snapshot.queryParamMap.get('user');
    this.form = this.formBuilder.group({
      user: [this.user, [Validators.required]],
      code: ['', [Validators.required]],
    });
  }


  signIn() {
    const redirectTo = this.activateRoute.snapshot.queryParamMap.get('user');
    if (this.form.valid) {
      const val = this.form.value;
      this.authService.confirmCode(val.user, val.code);
    }
  }
}
