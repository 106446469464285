import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {environment} from '../../../../environments/environment';
import {RxwebValidators} from '@rxweb/reactive-form-validators';

@Component({
  selector: 'app-forgot-password-submit',
  templateUrl: './forgot-password-submit.component.html',
  styleUrls: ['./forgot-password-submit.component.scss']
})
export class ForgotPasswordSubmitComponent implements OnInit {
  form: FormGroup;
  user;

  constructor(
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private router: Router,
    private activateRoute: ActivatedRoute) {

  }

  ngOnInit() {
    this.user = this.activateRoute.snapshot.queryParamMap.get('user');
    this.form = this.formBuilder.group({
      user: ['', [Validators.required, Validators.email]],
      code: ['', [Validators.required]],
      password: ['', [Validators.required, RxwebValidators.minLength({
        value: 8,
        message: 'Necesita mínimo 8 caracteres.'
      })]],
      confirm: ['', [Validators.required, RxwebValidators.compare({
        fieldName: 'password',
        message: 'Las contraseñas no coinciden'
      })]]
    });
  }


  recoverPassword() {
    if (this.form.valid) {
      const val = this.form.value;
      this.authService.forgotPasswordSubmit(val.user, val.code, val.password);
    }
  }
}
