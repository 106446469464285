import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {

  form: FormGroup;
  user;

  constructor(
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private router: Router,
    private activateRoute: ActivatedRoute) {

  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      user: [this.user || '', [Validators.required]],
      password: ['', [Validators.required]],
    });
  }

  signIn() {
    const redirectTo = this.activateRoute.snapshot.queryParamMap.get('redirectTo') || environment.redirectTo;
    const localRedirectTo = '/auth/signed';
    if (this.form.valid) {
      const val = this.form.value;
      this.authService.signInUser(val.user, val.password, redirectTo, localRedirectTo);
    }
  }

  goToForgotPassword() {
    // if (this.form.get('email').valid) {
    //   this.router.navigate(['/auth/forgot-password']);
    // } else {
      this.router.navigate(['/auth/forgot-password']);
   // }
  }
}
